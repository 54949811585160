// import logo from './logo.svg';
import React from 'react';
import './App.css';
import Navbar from './components/Navbar';
// import Footer from './components/Footer';
import Home from './components/Home'
import About from './components/About';
import { useState } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";
import Alert from './components/Alert';


function App() {
  const [mode, setMode] = useState("light");
  const [alert, setAlert] = useState(null)

  const showAlert = (message, type) => {
    setAlert({
      msg: message,
      type: type
    })
    setTimeout(() => {
      setAlert(null)
    }, 1500)
  }

  const toggleMode = () => {
    if (mode === 'light') {
      setMode('dark');
      document.body.style.backgroundColor = 'black';
      showAlert("Dark mode has been enabled","success")
    }
    else {
      setMode('light')
      document.body.style.backgroundColor = 'white';
      showAlert('Light mode has benn enabled','success')
    }
  }

  return (
    <Router>
      <div className="App">
        <Navbar title="SN Coding" aboutText="About Us" toggleMode={toggleMode} mode={mode} />
        <Alert alert={alert}/>
        <Routes>
          <Route path="/about" element={<About mode={mode} />}>
          </Route>
          <Route path="/" element={<Home heading="Text Utilities" mode={mode} showAlert={showAlert} />}>
          </Route>
        </Routes>



      </div>
    </Router>
  );
}

export default App;
