import { useState } from "react"

export default function Home(props) {
      const [data, setState] = useState("")
      function chnageUpper() {
            let newUpper = data.toUpperCase();
            setState(newUpper)
            props.showAlert("Converted to uppercase","success")
      }
      function chnageLower() {
            let newLower = data.toLowerCase();
            setState(newLower)
            props.showAlert("Converted to lowercase","success")
      }
      function clearText() {
            setState("");
            props.showAlert("Cleared text","success")
      }
      const handleCopy = () => {
            navigator.clipboard.writeText(data);
            props.showAlert("Copied, you can paste anywhere","success")
      }

      const handleExtraSpaces = () => {
            let newText = data.split(/[ ]+/);
            setState(newText.join(" "));
            props.showAlert("Removed extra space","success")
      }
      function textAreaChange(e) {
            setState(e.target.value)
      }
      return (
            <>
                  <div className="container">
                        <h2 className="my-3" style={{color:props.mode==='dark' ? 'white' : '#042743'}}>{props.heading}</h2>
                        <div className="form-group">
                              {/* <label htmlFor="myBox" className='mb-2'>{props.label}</label> */}
                              <textarea className="form-control" value={data} placeholder="Enter text here" onChange={(e) => { textAreaChange(e) }} id="myBox" rows="8"></textarea>
                              <button disabled={data.length === 0} onClick={() => { chnageUpper() }} className="btn btn-primary mt-3">Uppercase</button>

                              <button disabled={data.length === 0} onClick={() => { chnageLower() }} className="btn btn-primary mt-3 ms-2 ">Lowercase</button>
                              
                              <button disabled={data.length === 0} className="btn btn-primary mt-3 ms-2" onClick={handleCopy}>Copy Text</button>

                              <button disabled={data.length === 0} className="btn btn-primary mt-3 ms-2" onClick={handleExtraSpaces}>Remove Extra Spaces</button>

                              <button disabled={data.length === 0} onClick={() => { clearText() }} className="btn btn-primary mt-3 ms-2">Clear Text</button>

                        </div>
                  </div>
                  <div className="container">
                        <h2 className="my-3" style={{color:props.mode==='dark' ? 'white' : '#042743'}}>Your text summary</h2>
                        <p style={{color:props.mode==='dark' ? 'white' : '#042743'}}>{data.split(" ").length} Words {data.length} Characters</p>
                        <p style={{color:props.mode==='dark' ? 'white' : '#042743'}}>{0.008 * data.split(" ").length} minuts read</p>
                        <h2 className="my-3" style={{color:props.mode==='dark' ? 'white' : '#042743'}}>Your text preview</h2>
                        <p style={{color:props.mode==='dark' ? 'white' : '#042743'}}>{data}</p>
                  </div>
            </>
      )
}
