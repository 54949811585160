import React from 'react'

export default function Alert(props) {
      return (
            props.alert &&   <div style={{height:'50px'}}>
                  <div class={`alert alert-${props.alert.type}`} role="alert">
                        {props.alert.msg}
                  </div>
            </div>
      )
}
